import React, { useEffect, useState } from 'react';
import './connectAccount.scss';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from "react-spinners";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'; 


function LinkAccount() {

  const navigate = useNavigate();
  const selectedBankIcon = localStorage.getItem('selectedBankIcon');

  const initialState = {
    accountNumber: '',
  };

  const [formData, setFormData] = useState(initialState);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const [isValidateLoading, setIsValidateLoading] = useState(false);
  const [isInputClicked, setIsInputClicked] = useState({
    accountNumber: false,
  });

  const [isValidAccount, setIsValidAccount] = useState(false);
  const [fetchedAccountName, setFetchedAccountName] = useState('');

  const handleBack = () => {
    navigate('/pages/direct-select-bank');
  };

  const handleInputChange = (event) => {
  const { name, value } = event.target;
  setFormData((prevFormData) => ({
    ...prevFormData,
    [name]: value,
  }));

  // This is to check if the input is a 10-digit number
  if (value.length === 10 && /^\d+$/.test(value)) {
    setIsValidAccount(true);
  } else {
    setIsValidAccount(false);
  }

  // // Only trigger account validation if the input is valid
  // if (isValidAccount) {
  //   validateAccount();
  // }
  };
  useEffect(() => {
    if (isValidAccount) {
      validateAccount();
    }
    // eslint-disable-next-line
  }, [isValidAccount]);


  const handleInputContainerClick = (inputName) => {
    setIsInputClicked((prevState) => ({
      ...prevState,
      [inputName]: true,
    }));
  };

  const handleInputBlur = (inputName) => {
    setIsInputClicked((prevState) => ({
      ...prevState,
      [inputName]: false,
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    navigate('/pages/mandate-confirmation');
  }
  const bankCode = (localStorage.getItem('selectedBankCode'))
  const customerCode = (localStorage.getItem('customerCode'))
  const transactionId = (localStorage.getItem('transactionId'))


  const validateAccount = async () => {
    setIsValidateLoading(true);
    try {
      let data = JSON.stringify({
        account_number: formData.accountNumber,
        bank_code: bankCode,
        CustomerCode: customerCode,
        transactionId: transactionId
      });
    console.log (formData.accountNumber)
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://flick-app.com/direct-debit/inquiry",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("publicKey")}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));

      const accountName = response?.data?.data?.account_name;
      setFetchedAccountName(accountName);
    } catch (err) {
      setIsValidateLoading(false);
      console.log("The err:",err);

      toast.error("Kindly enter a valid account number.");
  
      // This is to clear account name if it was previously fetched
      setFetchedAccountName('');
    } finally {
      setIsValidateLoading(false);
    }

  };


  return (
    <div className="connect-account-overlay">
       <div className="connect-account-main">
        <div className="connect-account-box">
          <div className="choose-bank">
            <img
              src={process.env.PUBLIC_URL + '/images/arrowBack.svg'}
              alt="back"
              style={{ width: '16px', height: '16px' }}
              onClick={handleBack}
            />
            <p className="choose-your-bank">Link account</p>
            <img
              src={process.env.PUBLIC_URL + '/images/close.svg'}
              alt="close"
              style={{ marginBottom: '27px' }}
              onClick={handleBack}
            />
          </div>
          <div className='enter-credentials-box'>
            <div className='img-logo'>
              <img
                src={process.env.PUBLIC_URL + selectedBankIcon}
                alt="logo"
                style={{width: "47px", height: "48.958px"}}
              />
            </div>
            <form className="flick-link-form" style={{ marginTop: '10px' }} onSubmit={handleSubmit}>

              <div className="input_container" onBlur={() => handleInputBlur('accountNumber')}>
                <div className={`input-group ${isInputClicked.accountNumber ? 'clicked' : ''}`} onClick={() => handleInputContainerClick('accountNumber')}>
                  <input type="text" name="accountNumber" placeholder="Account number" className={`input-name`} onChange={handleInputChange} required />
                </div>
                <p className={`input-label ${isInputClicked.accountNumber ? 'clicked' : ''}`}>Account number </p>
              </div>
              {isValidAccount && (
                <div className='img-account-name'>
                  {isValidateLoading ? (
                    <BeatLoader color="#2EBDB6" />
                  ) : (
                    <>
                      {fetchedAccountName && <img src={process.env.PUBLIC_URL + "/images/check-circle-broken.svg"} alt="logo"/>}
                      <p className='account-name-img'>{fetchedAccountName}</p>
                    </>
                  )}
                </div>
              )}
              <button className={`btn ${isValidAccount ? '' : 'disabled'}`} style={{ background: '#2EBDB6', marginTop: "10px" }} type="submit" disabled={!isValidAccount || isLoading} >                {isLoading ? (
                  <BeatLoader
                    color="#FFF"
                    cssOverride={{}}
                  />
                ) : (
                  <>
                    Verify Account
                  </>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer className="custom-toast-container" />
    </div>
  );
}

export default LinkAccount;
