import React from 'react'
import './connectAccount.scss'
import { useNavigate } from "react-router-dom";

function DirectSuperWallet() {
    const dashboard = JSON.parse(localStorage.getItem('dashboard'));
    const navigate = useNavigate();
    const handleClick = () => {
      if(dashboard?.is_required_address || dashboard?.is_required_bvn || dashboard?.is_required_email || dashboard?.is_required_phone){
        return navigate('/pages/dashboard-details')
      } else{

          navigate("/pages/direct-select-bank");
      }
    };
    const handleClose = () => {
        navigate("/")
    }



  return (
    <div className='connect-account-overlay'>
        <div className='connect-account-main'>
            <div className='connect-account-box'> 
                <div  className='close-button'>
                    <img src={process.env.PUBLIC_URL + "/images/close.svg"} alt="close" onClick={handleClose}/>
                </div>
                <div className='flick-logo' style={{marginTop:"0px"}}>
                    <img src={process.env.PUBLIC_URL + "/images/round-flick-logo.svg"} alt="close"/>
                </div>
                <p className='super-wallet'>Setup a Direct Debit with Flick</p>
                <div className='connect-effortless' style={{gap:"20px"}}>
                   <div className='border-line'>
                        <div className='connect-title'>
                            <img src={process.env.PUBLIC_URL + "/images/connect.svg"} alt="logo"/>
                            <div className='connect-link'>
                                <p className='connect-your-account'>Secure</p>
                                <p className='securely-connect' style={{marginBottom:"10px"}}>Your data is encrypted and your details will not be accessible to Superwallet</p>
                            </div>
                        </div>
                    </div>
                    <div className='border-line'>
                        <div className='connect-title'>
                            <img src={process.env.PUBLIC_URL + "/images/private.svg"} alt="logo"/>
                            <div className='connect-link'>
                                <p className='connect-your-account'>Private</p>
                                <p className='securely-connect' style={{marginBottom:"10px"}}>Your credentials will never be made accessible to Flick.</p>
                            </div>
                        </div>
                    </div>
                    <div className='connect-title'>
                        <img src={process.env.PUBLIC_URL + "/images/protection.svg"} alt="logo"/>
                        <div className='connect-link'>
                            <p className='connect-your-account'>Protection</p>
                            <p className='securely-connect'>Neither Flick nor Flick have access to move your funds without your consent.</p>
                        </div>
                    </div>
                </div>
                <div className='flick-policy'>
                    <div className='agree-to'>
                        <p className='select-continue'>By selecting “Continue” you agree to the</p>
                        <a href="https://getflick.app/privacy-policy" className='flick-end-user'>Flick End User Privacy Policy</a>
                    </div>
                    <button className="btn" style={{background:"#2EBDB6"}} onClick={handleClick}>
                    Select Account
                        <img src={process.env.PUBLIC_URL + "/images/arrow-forward.svg"} alt="arrow btn" style={{ display: "inline", marginLeft: "5px" }}/>
                    </button>
                </div>

            </div>
        </div>
    </div>
  )
}

export default DirectSuperWallet