import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
function Redirect() {
    // eslint-disable-next-line
    const [isProcessing, setIsProcessing] = useState(false);
    const { param } = useParams();
    useEffect(()=>{
      validateUrl();
        // eslint-disable-next-line
    },[])
    const validateUrl = async ()=>{
        
        console.log("params: ",param)
    }
  return (
    <>
    <p></p>
    </>
  )
}

export default Redirect