import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import Lottie from 'lottie-react'
import loadingTransfer from './loadingTransfer.json';
import loadingTransferEnd from './loadingTransferEnd.json';
import { ToastContainer, toast } from 'react-toastify';
import './connectAccount.scss'
import axios from 'axios';

function MicroDeposit() {
    const location = useLocation()
    const navigate = useNavigate();
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line
    const [isLoadingCancel, setIsLoadingCancel] = useState(false);
    // const selectedBankIcon = localStorage.getItem('selectedBankIcon');
    const [countdown, setCountdown] = useState({ minutes: 1, seconds: 45 });
    // const [expired, setExpired] = useState(false);
    const [showAccountDetails, setShowAccountDetails] = useState(false);
    const [paymentConfirmed, setPaymentConfirmed] = useState(false);
    const [mandateStatus, setMandateStatus] = useState(null);



    const toggleAccountDetails = () => {
      setShowAccountDetails(!showAccountDetails);
    };

  // Timer function to update countdown every second
  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     if (countdown.minutes === 0 && countdown.seconds === 0) {
  //       clearInterval(timer);
  //       // setExpired(true);
  //       navigate('/pages/setup-complete');

  //     } else {
  //       setCountdown(prevCountdown => {
  //         if (prevCountdown.seconds === 0) {
  //           return { minutes: prevCountdown.minutes - 1, seconds: 59 };
  //         } else {
  //           return { minutes: prevCountdown.minutes, seconds: prevCountdown.seconds - 1 };
  //         }
  //       });
  //     }
  //   }, 1000);
  //   return () => clearInterval(timer);
  //   // eslint-disable-next-line 
  // }, [countdown]);

    const message = (localStorage.getItem('message'))
    const bankName = (localStorage.getItem('bankName'))
    const accountNumber = (localStorage.getItem('accountNumber'))
    const transferAccountName = (localStorage.getItem('transferAccountName'))

    // Fetching generatedDetails from location state
    const generatedDetails = location.state && location.state.generatedDetails;
    // Destructuring generatedDetails
    const { generatedMessage, generatedBankName, generatedAccountNumber, generatedAccountName } = generatedDetails || {};

    const handleBack =() => {
      navigate('/pages/mandate-confirmation');
    }

    const transactionId = localStorage.getItem("transactionId");

    const handleSend = async () => {
      setIsLoading(true);
      setPaymentConfirmed(true);
      // Start countdown
      const timer = setInterval(() => {
        setCountdown(prevCountdown => {
          if (prevCountdown.minutes === 0 && prevCountdown.seconds === 0) {
            clearInterval(timer);
            navigate('/pages/setup-complete');
            return { minutes: 0, seconds: 0 };
          } else if (prevCountdown.seconds === 0) {
            return { minutes: prevCountdown.minutes - 1, seconds: 59 };
          } else {
            return { minutes: prevCountdown.minutes, seconds: prevCountdown.seconds - 1 };
          }
        });
      }, 1000);

      try {
        const checkMandateStatus = async () => {
          const config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `https://flick-app.com/direct-debit/status?transactionId=${transactionId}`,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("publicKey")}`,
            },
          };
          const response = await axios.request(config);
          console.log(JSON.stringify(response.data));
          setMandateStatus(response?.data?.data?.mandate_status);
          if (response?.data?.data?.mandate_status === "00") {
            setIsLoading(false);
            navigate('/pages/setup-complete');
            clearInterval(intervalId);
          }
        };
        const intervalId = setInterval(checkMandateStatus, 10000); // This will make the API call every 10 seconds
        checkMandateStatus(); // The initial call
      } catch (err) {
        console.log(err);
        let errorMessage = err.response.data.message;
        if (err.response && err.response.data && err.response.data.message) {
          errorMessage = err.response.data.message;
        }
        setIsLoading(false);
        toast.error(errorMessage)
      }
    };

    const handleCopyAccountNumber = () => {
      const textToCopy = generatedAccountNumber || accountNumber;
      navigator.clipboard.writeText(textToCopy);
      toast.success("Copied!");
  };
    // const handleCancel = () => {
    //     // navigate('/pages/choose-authorization');
    //     navigate('/pages/mandate-confirmation');
    // }

    return (
        <div className="connect-account-overlay">
          <div className="connect-account-main">
            <div className="connect-account-box">
            {!paymentConfirmed ? (
              <div className='autho'>
                <div className="choose-bank">
                {/* {!generatedDetails && (
                    <img
                        src={process.env.PUBLIC_URL + '/images/arrowBack.svg'}
                        alt="back"
                        style={{ width: '16px', height: '16px' }}
                        onClick={handleBack}
                    />
                )}  */}
                <img
                    src={process.env.PUBLIC_URL + '/images/micro-arrow.svg'}
                    alt="close"
                    onClick={handleBack}
                  />
                
                  {/* <img
                    src={process.env.PUBLIC_URL + '/images/close.svg'}
                    alt="close"
                    onClick={handleBack}
                  /> */}
                </div> 
                <p className="choose-your-bank" style={{padding:"16px 0px 0 24px"}}>Authorise via micro-deposit</p>
                {/* <div className="flick-logo" style={{ position: 'relative'}}>
                  <img
                    src={process.env.PUBLIC_URL + selectedBankIcon}
                    alt="bank logo"
                    style={{width: "47px", height: "48.958px"}}
                  />
                </div> */}
                <div className="connect-effortless" style={{gap:"0px"}}>
                  <div className='transfer'>
                    <div className='transfer-to'>
                      <p className='transfer-amount'>{generatedMessage || message}</p>
                      <div className='micro-line'></div>
                      <div className='authorization'>
                          <div className='authorize'>
                            <div className='nibss-details'>
                              <p className='nibss-detail'>Account Name</p>
                              <p className='account-copy'>{generatedBankName || bankName}</p>
                            </div>
                            <div className='nibss-details'>
                              <p className='nibss-detail'>Account Number</p>
                              <p className='account-copy'>{generatedAccountNumber || accountNumber}<span style={{cursor:"pointer"}} onClick={handleCopyAccountNumber}><img src={process.env.PUBLIC_URL + '/images/copy.svg'} alt="copy"/></span></p>
                            </div>
                            <div className='nibss-details'>
                              <p className='nibss-detail'>Account Type</p>
                              <p className='account-copy' style={{textAlign:"right"}}>{generatedAccountName || transferAccountName}</p>
                            </div>
                          </div>
                          {/* <div>
                              <img src={process.env.PUBLIC_URL + selectedBankIcon} alt="icon" style={{width: "24.96px", height: "26px"}}/>
                          </div> */}
                      </div>
                    </div>
                    {/* {expired ? (
                      <p className="transfer-only">This account is for this transfer only. It has expired.</p>
                    ) : (
                      <p className="transfer-only">
                        This account is for this transfer only. It expires in {`${countdown.minutes}:${countdown.seconds
                          .toString()
                          .padStart(2, '0')}`}
                      </p>
                    )} */}
                  </div>
                  <div className='buttons'>
                    {/* <button className="btn" style={{ background: '#FFF', color:"#161925", border: "1px solid #EAECF0" }} onClick={handleCancel} disabled={isLoading || isLoadingCancel}>
                      {isLoadingCancel ? (
                        <BeatLoader color="#FFF" cssOverride={{}} />
                      ) : (
                        <>
                          Cancel
                        </>
                      )}
                    </button> */}
                    <button className="btn" style={{ background: '#2EBDB6' }} onClick={handleSend} disabled={isLoading || isLoadingCancel}>
                      {isLoading ? (
                        <BeatLoader color="#FFF" cssOverride={{}} />
                      ) : (
                        <>
                          I’ve made payment
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            ) : ( 
              <div className='pay-confirm'>
                <p className="choose-your-bank" style={{padding:"20px 0px 0 24px"}}>Payment Confirmation</p>
                <div className="connect-effortless" style={{marginBottom:"10px"}}>
                  <div className='transfer'>
                    <p className='transfer-amount' style={{fontSize:"13px"}}>We’re waiting to confirm your transfer. This can take a few minutes</p>

                    <div className="lottie-size">
                      {mandateStatus === "00" ? (
                        <Lottie loop={false} animationData={loadingTransferEnd} />
                      ) : (
                        <Lottie animationData={loadingTransfer} />
                      )}
                    </div>

                    <p className="transfer-only"><span style={{marginRight:"5px"}}><img src={process.env.PUBLIC_URL + '/images/information.svg'} alt="in"/></span>
                            This account is for this transfer only. It expires in  <span style={{color:"#333", fontWeight:"700"}}> {`${countdown.minutes}:${countdown.seconds
                              .toString()
                              .padStart(2, '0')}`} </span>
                    </p>
                    <p className="show-account" onClick={toggleAccountDetails}>
                      {showAccountDetails ? 'Hide account details' : 'Show account details'}
                    </p>
                    {showAccountDetails && 
                    <>
                      <div className='micro-line'></div>
                      <p className='show-details'>Bank Details</p>
                      <div className='authorization' style={{background:"#F4FBFB", padding:"15.5px 23.5px"}}>
                        <div className='authorize'>
                          <div className='nibss-details'>
                            <p className='nibss-detail'>Account Name</p>
                            <p className='account-copy'>{generatedBankName || bankName}</p>
                          </div>
                          <div className='nibss-details'>
                            <p className='nibss-detail'>Account Number</p>
                            <p className='account-copy'>{generatedAccountNumber || accountNumber}<span style={{cursor:"pointer"}} onClick={handleCopyAccountNumber}><img src={process.env.PUBLIC_URL + '/images/copy.svg'} alt="copy"/></span></p>
                          </div>
                          <div className='nibss-details'>
                            <p className='nibss-detail'>Account Type</p>
                            <p className='account-copy' style={{textAlign:"right"}}>{generatedAccountName || transferAccountName}</p>
                          </div>
                        </div>
                      </div>
                    </>
                    }
                  </div>
              </div>

              </div>
            )}
            </div>
          </div>
          <ToastContainer />
        </div>
      );
}

export default MicroDeposit