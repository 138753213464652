import React, { useEffect, useState } from 'react'
import axios from 'axios';
import {  useNavigate } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';

function VerifyPage() {
    const [isProcessing, setIsProcessing] = useState(false);
    useEffect(()=>{
        validateUrl();
        // eslint-disable-next-line
    },[])
    const navigate = useNavigate();

    function numberWithCommas(x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }
    

    const validateUrl = async () => {

      setIsProcessing(true)
        // const params  = "https://direct.debit.paywithflick.co/ufJShDCXF62KifRxfiYvPx";
        const params  = window.location.href;
        const urlId = params.split('/');
        const id = urlId[urlId.length-1]
        localStorage.setItem("urlParam", id)
        

      try {
        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `https://flick-app.com/direct-debit/validation?hash=${id}`,
          headers: {
          },
        };
        const response = await axios.request(config);
        // console.log("validation response", JSON.stringify(response.data));
        console.log("validation response",response.data);

        const dashBoardData = {
          is_required_address : response.data?.data?.is_required_address,
          is_required_bvn : response.data?.data?.is_required_bvn,
          is_required_email : response.data?.data?.is_required_email,
          is_required_phone : response.data?.data?.is_required_phone,
          CustomerCode : response.data?.data?.CustomerCode,
          transactionId : response.data?.data?.transactionId,
          publicKey : response.data?.data?.publicKey,
          
        } 

        localStorage.setItem("dashboard",JSON.stringify(dashBoardData));
  
        localStorage.setItem("customerCode",response?.data?.data?.CustomerCode);
        localStorage.setItem("transactionId",response?.data?.data?.transactionId);
        localStorage.setItem('banksAllowed', JSON.stringify(response?.data?.data?.banks_allowed?.banksData));
        localStorage.setItem("firstDebit",response?.data?.data?.amount_per_charge);
        localStorage.setItem("totalDebit", response?.data?.data?.total_charge_amount)
        localStorage.setItem("publicKey", response?.data?.data?.publicKey)
        localStorage.setItem("frequency", response?.data?.data?.frequency)
        localStorage.setItem("startDate", response?.data?.data?.start_date)
        localStorage.setItem("endDate", response?.data?.data?.end_date)
        localStorage.setItem("validity", response?.data?.data?.total_expected_deductions)
        localStorage.setItem("charge_date", response?.data?.data?.charge_date)
        localStorage.setItem("is_onetime", response?.data?.data?.is_onetime)


        if (response?.status === 200) {
          setIsProcessing(false);
          navigate('/direct-debit');
        }
        if (response?.data?.data?.is_generated_previously === true) {
          setIsProcessing(false)
          navigate('/pages/micro-deposit', {
              state: {
                  generatedDetails: {
                      generatedAccountNumber: response?.data?.data?.dd_account_no,
                      generatedBankName: response?.data?.data?.dd_bank_name,
                      generatedAccountName: response?.data?.data?.dd_account_name,
                      generatedMessage: response?.data?.data?.message,
                      generatedFee: response?.data?.data?.fee
                  }
              }
          });
      }

      localStorage.setItem("payerAccountNumber", response?.data?.data?.payer_account_number)
      localStorage.setItem("payerBankCode", response?.data?.data?.payer_bank_code)
      localStorage.setItem("payerBankName", response?.data?.data?.payer_bank_name)
      localStorage.setItem("generatedAccountNumber", response?.data?.data?.dd_account_no)
      localStorage.setItem("generatedBankName", response?.data?.data?.dd_bank_name)
      localStorage.setItem("generatedAccountName", response?.data?.data?.dd_account_name)
      localStorage.setItem("generatedMessage", response?.data?.data?.message)
      localStorage.setItem("generatedFee", response?.data?.data?.fee)
      // } catch (err) {
      //   console.log(err);
      //   const errorMessage = err?.message || "unable to fetch data";
      //   console.log("Error:",errorMessage)
      //   // setIsProcessing(false);
      //   // navigate('/invalid');
      //   localStorage.setItem("errorMessage", errorMessage)
      // }
      } catch (err) {
        console.log(err);
        // let errorMessage = "Unable to fetch data";
        let errorMessage = err.response.data.message;
        if (err.response && err.response.data && err.response.data.message) {
          errorMessage = err.response.data.message;
        }
        console.log(errorMessage);
        // localStorage.setItem("errorMessage", errorMessage);
        setIsProcessing(false);
        navigate('/invalid', { state: { errorMessage: errorMessage } });
      }
      
    };

  return (
    <div className="connect-account-overlay" style={{position:"relative", display:"flex", alignItems:"center", justifyContent:"center", height:"100%"}}>
          <div className='loading_overlay' style={{background:"#fff"}}>
              <div className='loader_icon' >
                {
                    isProcessing &&
                    <FadeLoader
                    cssOverride={{marginRight:"auto",marginLeft:"auto",marginTop:"50vh"}}
                    color="#259792"
                    />
                }
              </div>
          </div>
   </div>
  )
}

export default VerifyPage
