import React, { useState } from 'react';
import './connectAccount.scss';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { BeatLoader } from 'react-spinners';

function ChooseAuthorization() {

  const navigate = useNavigate();
  const selectedBankIcon = localStorage.getItem('selectedBankIcon');
  const selectedBankCode = localStorage.getItem('selectedBankCode');
  const [selectedOption, setSelectedOption] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleClick = () => {
    if (!selectedOption) {
      toast.error('Please select an authorization method');
      return;
    }

    // if (!connectionMethod(selectedBankCode, selectedOption)) {
    //   toast.error(
    //     `Connection method not avaiable`
    //   );
    //   return;
    // }

    setIsLoading(true);
    localStorage.setItem('selectedOption', selectedOption);
    if (selectedOption === "micro_deposit") {
      setIsLoading(false);
      navigate('/pages/micro-deposit', {
        state: {
          selectedBankIcon: selectedBankIcon,
          selectedBankCode: selectedBankCode,
        },
      });
    } else if (selectedOption === "signature") {
      setIsLoading(false);
      navigate('', {
        state: {
          selectedBankIcon: selectedBankIcon,
          selectedBankCode: selectedBankCode,
        },
      }); 
    // else {
    //   setIsLoading(false);
    //   console.error('Error:', 'Bank code cannot be empty');
    }
  };

  const handleBack = () => {
    navigate('/pages/mandate-confirmation');
  };

  return (
    <div className="connect-account-overlay">
      <div className="connect-account-main">
        <div className="connect-account-box">
          <div className="choose-bank">
            <img
              src={process.env.PUBLIC_URL + '/images/arrowBack.svg'}
              alt="back"
              style={{ width: '16px', height: '16px' }}
              onClick={handleBack}
            />
            <p className="choose-your-bank">Choose authorisation method</p>
            <img
              src={process.env.PUBLIC_URL + '/images/close.svg'}
              alt="close"
              onClick={handleBack}
            />
          </div>
          <div className="flick-logo" style={{ position: 'relative', marginTop: '20px'}}>
            <img
              src={process.env.PUBLIC_URL + selectedBankIcon}
              alt="bank logo"
              style={{width: "47px", height: "48.958px"}}
            />
          </div>
          <div className="connect-effortless">
              <div className='border-line'>
                  <div className='connect-title' onClick={() => setSelectedOption('micro_deposit')}>
                      <img src={process.env.PUBLIC_URL + "/images/micro-deposit.svg"} alt="logo"/>
                      <div className='connect-link'>
                          <p className='connect-your-account'>Authorise via micro-deposit</p>
                          <p className='securely-connect' style={{marginBottom:"18px"}}>Make a transfer of a token amount to complete setup</p>
                      </div>
                      <input
                        type="radio"
                        name="options"
                        value="micro_deposit"
                        checked={selectedOption === 'micro_deposit'}
                        onChange={handleOptionChange}
                        className="custom-radio"
                      />
                  </div>
              </div>
            
              <div className='connect-title' onClick={() => setSelectedOption('signature')}>
                  <img src={process.env.PUBLIC_URL + "/images/signature.svg"} alt="logo"/>
                  <div className='connect-link'>
                      <p className='connect-your-account'>Authorise via signature</p>
                      <p className='securely-connect'>Upload or scan your signature to complete setup</p>
                  </div>
                  <input
                    type="radio"
                    name="options"
                    value="signature"
                    checked={selectedOption === 'signature'}
                    onChange={handleOptionChange}
                    className="custom-radio"
                  />
              </div>
            <button className="btn" style={{ background: '#2EBDB6' }} onClick={handleClick} disabled={isLoading}>
              {isLoading ? (
                <BeatLoader color="#FFF" cssOverride={{}} />
              ) : (
                <>
                  Continue
                  <img src={process.env.PUBLIC_URL + '/images/arrow-forward.svg'} alt="arrow btn" style={{ display: 'inline', marginLeft: '5px' }}/>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ChooseAuthorization;
