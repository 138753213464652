import React, {  useState } from 'react';
import './connectAccount.scss';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from "react-spinners";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'; 

function DashboardLink() {

    const dashboard = JSON.parse(localStorage.getItem('dashboard'));
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const initialState = {
        address: '',
        email: '',
        phone: '',
        bvn: ''
    };
    
    const [formData, setFormData] = useState(initialState);
    const [isInputClicked, setIsInputClicked] = useState({
        address: false,
        email: false,
        phone: false,
        bvn: false
    });
    
    const handleClose = () => {
        navigate('/direct-debit');
    };
    
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleInputBlur = (inputName) => {
        setIsInputClicked((prevState) => ({
            ...prevState,
            [inputName]: false,
        }));
    };

    const handleSubmit = async (e) => {
        setIsLoading(true)
        e.preventDefault();
        try{

            const headers= {
                Authorization: `Bearer ${localStorage.getItem("publicKey")}`,
                "Content-Type": "application/json",
            }
            const data = {
                bvn: formData.bvn,
                address:formData.address,
                phone: formData.phone,
                email: formData.email,
                CustomerCode: dashboard?.CustomerCode,
                transactionId: dashboard?.transactionId
            }

            // console.log("payload: ",data)

            const url ="https://flick-app.com/direct-debit/bvn-address-update"

            // eslint-disable-next-line 
            let res = await axios.post(url,{...data},{headers:headers});
            navigate('/pages/direct-select-bank')
            setIsLoading(false)

        }catch(err){
            setIsLoading(false)
            console.log(err)
            const errorMessage = err?.response?.data?.message || "An Error has occured"
            toast.error(errorMessage);
        }
     
       
    };

    const handleInputContainerClick = (inputName) => {
        setIsInputClicked((prevState) => ({
            ...prevState,
            [inputName]: true,
        }));
    };
    
    return (
        <div className="connect-account-overlay">
            <div className="connect-account-main">
                <div className="connect-account-box">
                    <div className='close-button'>
                        <img src={process.env.PUBLIC_URL + "/images/close.svg"} alt="close" onClick={handleClose}/>
                    </div>
                    <div className='flick-logo' style={{marginTop:"0px"}}>
                        <img src={process.env.PUBLIC_URL + "/images/round-flick-logo.svg"} alt="close"/>
                    </div>
                    <p className='super-wallet'>Enter Your Details Below</p>
                    <div className='enter-credentials-box'>
                        <form className="flick-link-form" style={{ marginTop: '10px' }} onSubmit={handleSubmit}>
                        {/* dashboard.is_required_address || dashboard.is_required_bvn || dashboard.is_required_email || dashboard.is_required_phone */}
                            {
                                dashboard?.is_required_address &&
                                <div className="input_container" onBlur={() => handleInputBlur('address')}>
                                    <div className={`input-group ${isInputClicked.address ? 'clicked' : ''}`} onClick={() => handleInputContainerClick('address')}>
                                        <input type="text" name="address" placeholder="Address" className={`input-name`} onChange={handleInputChange} required />
                                    </div>
                                    <p className={`input-label ${isInputClicked.address ? 'clicked' : ''}`}>Address</p>
                                </div>
                            }
                            {
                                dashboard?.is_required_email &&
                                <div className="input_container" onBlur={() => handleInputBlur('email')}>
                                    <div className={`input-group ${isInputClicked.email ? 'clicked' : ''}`} onClick={() => handleInputContainerClick('email')}>
                                        <input type="email" name="email" placeholder="Email" className={`input-name`} onChange={handleInputChange} required />
                                    </div>
                                    <p className={`input-label ${isInputClicked.email ? 'clicked' : ''}`}>Email</p>
                                </div>
                            }
                            {
                                dashboard?.is_required_phone &&
                                <div className="input_container" onBlur={() => handleInputBlur('phone')}>
                                    <div className={`input-group ${isInputClicked.phone ? 'clicked' : ''}`} onClick={() => handleInputContainerClick('phone')}>
                                        <input type="tel" name="phone" placeholder="Phone" className={`input-name`} onChange={handleInputChange} required />
                                    </div>
                                    <p className={`input-label ${isInputClicked.phone ? 'clicked' : ''}`}>Phone</p>
                                </div>
                            }
                            {
                                dashboard?.is_required_bvn &&
                                <div className="input_container" onBlur={() => handleInputBlur('bvn')}>
                                    <div className={`input-group ${isInputClicked.bvn ? 'clicked' : ''}`} onClick={() => handleInputContainerClick('bvn')}>
                                        <input type="text" name="bvn" placeholder="BVN" className={`input-name`} onChange={handleInputChange} required />
                                    </div>
                                    <p className={`input-label ${isInputClicked.bvn ? 'clicked' : ''}`}>BVN</p>
                                </div>
                            }
                            <button className={`btn`} style={{ background: '#2EBDB6', marginTop: "10px" }} type="submit" disabled={isLoading}>
                                {isLoading ? (
                                    <BeatLoader color="#FFF" cssOverride={{}} />
                                ) : (
                                    <>Proceed</>
                                )}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer className="custom-toast-container" />
        </div>
    );
}

export default DashboardLink;
