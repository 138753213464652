import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import axios from 'axios'; 


function MandateConfirmation() {
  const navigate = useNavigate();
  // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false);

    function numberWithCommas(x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }

    function renderDiv(onetime) {
      const isLoggedIn = onetime;
      return (
        <div>
          {isLoggedIn == "false" ? (
              <div className="border-top-line">
              <div className="text-div">
                  <p className="light-text">Total Amount</p>
                  <p className="light-text">End date</p>
              </div>
              <div className="text-div">
                  <p className="dark-text">NGN{totalDebit}</p>
                  <p className="dark-text">{endDate}</p>
              </div>
          </div>
      ) : (
            <div></div>
          )}
        </div>
      );
    }

    
    function renderDiv2(onetime) {
      const isLoggedIn = onetime;
      return (
        <div>
          {isLoggedIn == "false" ? (
                <div className="border-top-line">
                <div className="text-div">
                    <p className="light-text">Debit Amount</p>
                    <p className="light-text">Start date</p>
                </div>
                <div className="text-div">
                    <p className="dark-text">NGN{mandateAmount}</p>
                    <p className="dark-text">{startDate}</p>

                </div>
            </div>
  ) : (
            <div></div>
          )}
        </div>
      );
    }

    
    function renderDiv3(onetime) {
      const isLoggedIn = onetime;
      return (
        <div>
          {isLoggedIn == "true" ? (
              <div className="border-top-line">
              <div className="text-div">
                  <p className="light-text">Total Amount</p>
                  <p className="light-text">Charge date</p>
              </div>
              <div className="text-div">
                  <p className="dark-text">NGN{totalDebit}</p>
                  <p className="dark-text">{charge_date}</p>
              </div>
          </div>
) : (
            <div></div>
          )}
        </div>
      );
    }

    console.log("the is_onetime ", (localStorage.getItem('is_onetime')))

    const mandateAmount = numberWithCommas(localStorage.getItem('firstDebit'));
    const validity = (localStorage.getItem('validity'))
    const startDate = (localStorage.getItem('startDate'))
    const endDate = (localStorage.getItem('endDate'))
    const totalDebit = numberWithCommas(localStorage.getItem('totalDebit'));
    const frequency = (localStorage.getItem('frequency'))
    const reference = (localStorage.getItem('transactionId'))
    const is_onetime = (localStorage.getItem('is_onetime'))
    const charge_date = (localStorage.getItem('charge_date'))
    const customerCode = (localStorage.getItem('customerCode'))
    const transactionId = (localStorage.getItem('transactionId'))

    const handleClick = async () => {
      setIsLoading(true)
        try {
          let data = JSON.stringify({
            CustomerCode: customerCode,
            transactionId: transactionId
          });
    
          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: "https://flick-app.com/direct-debit/create-instruction",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("publicKey")}`,
              "Content-Type": "application/json",
            },
            data: data,
          };
          console.log("create-instruction-config",config);
          const response = await axios.request(config);
          console.log(JSON.stringify(response.data));
    
          localStorage.setItem("message", response?.data?.message);
          localStorage.setItem("accountNumber", response?.data?.data?.account_no);
          localStorage.setItem("transferAccountName", response?.data?.data?.account_name);
          localStorage.setItem("bankName", response?.data?.data?.bank);

          setIsLoading(false);
          navigate('/pages/micro-deposit');

        } catch (err) {
          setIsLoading(false);
          console.log(err);
          if (err.response && err.response.data && err.response.data.message && err.response.data.message.data && err.response.data.message.data.message) {
            const errorMessage = err.response.data.message.data.message.replace('errorCode[0]:: ', ''); // This is to remove the prefix
            // eslint-disable-next-line
            const formattedErrorMessage = errorMessage.replace(/[\[\]]/g, ''); // This is to remove square brackets
            toast.error(formattedErrorMessage);
          }
        } 
    }

    const handleBack = () => {
        navigate('/pages/link-account');
      };
  return (
    <div className="connect-account-overlay">
      <div className="connect-account-main">
        <div className="connect-account-box">
          <div className="choose-bank">
            <img src={process.env.PUBLIC_URL + "/images/arrowBack.svg"} alt="back" style={{ width: "16px", height: "16px" }} onClick={handleBack}/>
            <p className="choose-your-bank">Mandate confirmation</p>
            <img src={process.env.PUBLIC_URL + "/images/close.svg"} alt="close" onClick={handleBack}/>
          </div>
          <div className="mandate">
            <p className="setup-mandate">Setup mandate with Flick for</p>
            <h4 className="mandate-amount">NGN{mandateAmount}</h4>
          </div>
          <div className="connect-effortless" style={{gap:"0px"}}>

            <div className="border-top-line">
                <div className="text-div">
                    <p className="light-text">Type</p>
                    <p className="light-text">Validity</p>
                </div>
                <div className="text-div">
                    <p className="dark-text">Fixed mandate </p>
                    <p className="dark-text">{frequency === "daily"? validity+" day(s)" : frequency === "monthly"? validity+" month(s)" : frequency === "weekly"? validity+" week(s)" : frequency === "once"? "one-time" : ""}</p>
                </div>
            </div>


          {renderDiv2(is_onetime)}
          {renderDiv(is_onetime)}
          {renderDiv3(is_onetime)}

            

            <div className="border-top-line">
                <div className="text-div">
                    <p className="light-text">Reference</p>
                    <p className="light-text">Frequency</p>
                </div>
                <div className="text-div">
                    <p className="dark-text">{reference}</p>
                    <p className="dark-text">{frequency}</p>
                </div>
            </div>


          </div>
          <div className="flick-policy">
            <div className="agree-to">
              <p className="select-continue">To setup this mandate, you need to authorise</p>
              <p className="flick-end-user" style={{textDecorationLine:"none"}}>Flick Direct Debit</p>
            </div>
            <button className="btn" style={{ background: '#2EBDB6' }} onClick={handleClick} disabled={isLoading}>
              {isLoading ? (
                <BeatLoader color="#FFF" cssOverride={{}} />
              ) : (
                <>
                  Continue
                  <img src={process.env.PUBLIC_URL + '/images/arrow-forward.svg'} alt="arrow btn" style={{ display: 'inline', marginLeft: '5px' }}/>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
      <ToastContainer className="custom-toast-container" />
    </div>
  );
}

export default MandateConfirmation;
