import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DirectChooseYourAccount from './components/directdebitlookup/DirectChooseYourAccount';
import DirectLinkAccount from './components/directdebitlookup/DirectLinkAccount';
import OpenDirectSelectBanks from './components/directdebitlookup/OpenDirectSelectBanks';
import DirectMandateConfirmation from './components/directdebitlookup/DirectMandateConfirmation';
import BVNConsent from './components/directdebitlookup/BVNConsent';
import TransactionOtp from './components/directdebitlookup/TransactionOtp';
import ConsentReceived from './components/directdebitlookup/ConsentReceived';
import RaveLoan from './components/directdebitlookup/RaveLoan';
import DirectAssociatedChooseYourBank from './components/directdebitlookup/DirectAssociatedChooseYourBank';
import DirectAssociatedAccounts from './components/directdebitlookup/DirectAssociatedAccounts';
import DirectAssociatedCredentials from './components/directdebitlookup/DirectAssociatedCredentials';
import InvalidAccount from './components/directdebitlookup/InvalidAccount';
import InputOtp from './components/directdebitlookup/InputOtp';
import DirectChooseBank from './components/directdebitlookup/DirectChooseBank';
import DirectAccountConnected from './components/directdebitlookup/DirectAccountConnected';
import ChooseAuthorization from './components/directdebitlookup/ChooseAuthorization';
import AnotherLink from './components/directdebitlookup/AnotherLink';
import AnotherChooseYourBank from './components/directdebitlookup/AnotherChooseYourBank';
import AnotherCredentials from './components/directdebitlookup/AnotherCredentials';
import AnotherOtp from './components/directdebitlookup/AnotherOtp';
import ConnectSuccessful from './components/directdebitlookup/ConnectSuccessful';
import ContinueLater from './components/directdebitlookup/ContinueLater';
import SetupComplete from './components/directdebitlookup/SetupComplete';
import DirectSuperWallet from './components/directdebit/DirectSuperWallet';
import DirectSelectBanks from './components/directdebit/DirectSelectBanks';
import LinkAccount from './components/directdebit/LinkAccount';
import MandateConfirmation from './components/directdebit/MandateConfirmation';
import ChooseYourAccount from './components/directdebit/ChooseYourAccount';
import MicroDeposit from './components/directdebit/MicroDeposit';
import VerifyPage from './components/pages/VerifyPage';
import PageInvalid from './components/pages/PageInvalid';
import NotFound from './components/pages/NotFound';
import Redirect from './components/pages/Redirect';
import DashboardLink from './components/directdebit/DashboardLink';

function App() {
  return (
    <Router>
      <Routes>
        <Route>
          <Route path='/:id' element={<VerifyPage />} />
          <Route path="/direct-debit" element={<DirectSuperWallet />} />
          <Route path='/' element={<NotFound />} />
          <Route path='/redirect/:param' element={<Redirect />} />
          <Route path='/invalid' element={<PageInvalid />} />
          <Route path="/pages/direct-select-bank" element={<DirectSelectBanks />} />
          <Route path="/pages/choose-authorization" element={<ChooseAuthorization />} />
          <Route path="/pages/choose-account" element={<ChooseYourAccount />} />
          <Route path="/pages/link-account" element={<LinkAccount />} />
          <Route path="/pages/mandate-confirmation" element={<MandateConfirmation />} />
          <Route path="/pages/direct-choose-account" element={<DirectChooseYourAccount />} />
          <Route path="/pages/direct-link-account" element={<DirectLinkAccount />} />
          <Route path="/pages/open-direct-select-bank" element={<OpenDirectSelectBanks />} />
          <Route path="/pages/debit-mandate-confirmation" element={<DirectMandateConfirmation />} />
          <Route path="/pages/bvn-consent" element={<BVNConsent />} />
          <Route path="/pages/enter-otp" element={<TransactionOtp />} />
          <Route path="/pages/consent-received" element={<ConsentReceived />} />
          <Route path="/pages/rave-loan" element={<RaveLoan />} />
          <Route path="/pages/open-associated-choose-bank" element={<DirectAssociatedChooseYourBank />} />
          <Route path="/pages/direct-accounts" element={<DirectAssociatedAccounts />} />
          <Route path="/pages/direct-enter-credentials" element={<DirectAssociatedCredentials />} />
          <Route path="/pages/invalid" element={<InvalidAccount />} />
          <Route path="/pages/input-otp" element={<InputOtp />} />
          <Route path="/pages/direct-choose-bank" element={<DirectChooseBank />} />
          <Route path="/pages/connected" element={<DirectAccountConnected />} />
          <Route path="/pages/choose-authorization" element={<ChooseAuthorization />} />
          <Route path="/pages/micro-deposit" element={<MicroDeposit />} />
          <Route path="/pages/another-link" element={<AnotherLink />} />
          <Route path="/pages/another-choose-bank" element={<AnotherChooseYourBank />} />
          <Route path="/pages/another-credentials" element={<AnotherCredentials />} />
          <Route path="/pages/another-bank-otp" element={<AnotherOtp />} />
          <Route path="/pages/connect-successful" element={<ConnectSuccessful />} />
          <Route path="/pages/continue-later" element={<ContinueLater />} />
          <Route path="/pages/setup-complete" element={<SetupComplete />} />
          <Route path="/pages/dashboard-details" element={<DashboardLink />} />
        
        </Route>
      </Routes>
  </Router>
  );
}

export default App;
