import React, { useState } from "react";
import "./connectAccount.scss";
import { useNavigate} from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { ToastContainer, toast} from "react-toastify";

function DirectAssociatedAccounts() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBank, setSelectedBank] = useState(null);


  const handleClick = async () => {
    if (!selectedBank) {
      toast.error("Please select a bank");
      return;
    }
    localStorage.setItem('selectedBank', selectedBank); 
    setIsLoading(true)
    navigate("/pages/open-associated-choose-bank")
  };
  const handleConnect = async () => {
    setIsLoading(true)
    navigate("/pages/open-direct-select-bank")
  };
  const handleBankChange = (event) => {
    setSelectedBank(event.target.value);
  };
  const handleBack = () => {
    navigate("/pages/rave-loan");
  };

  return (
    <div className="connect-account-overlay">
      <div className="connect-account-main">
        <div className="connect-account-box">
          <div className="choose-bank">
            <img
              src={process.env.PUBLIC_URL + "/images/arrowBack.svg"}
              alt="back"
              style={{ width: "16px", height: "16px" }}
              onClick={handleBack}
            />
            <img
              src={process.env.PUBLIC_URL + "/images/close.svg"}
              alt="close"
              onClick={handleBack}
            />
          </div>
          <div className="associated-account" style={{borderBottom:"1px solid rgba(231, 234, 238, 0.40)", paddingBottom:"10px"}}>
            <img src={process.env.PUBLIC_URL + "/images/ass-flick.svg"} alt="logo"/>
            <p className="link-associated">Link Account</p>
            <p className="rave-we-found">RaveLoan has requested you to link a min. of two (2) active accounts</p>
          </div>
          <div style={{ padding: "0px 21px 21px 21px" }}>
            <div className="account-type-container">
                <div className="account-type" onClick={() => setSelectedBank('gtb')} >
                  <div className="account-type-text" style={{display:"flex", flexDirection:"row"}}>
                  <div>
                    <img src={process.env.PUBLIC_URL + "/images/ass-gtbank.svg"} alt="arrow btn"/>
                  </div>
                  <div>
                    <p className="type-of-account">GTBank</p>
                    <p className="account-number">025••••521</p>
                  </div>
                  </div>
                  <input
                    type="radio"
                    name="options"
                    value="gtb"
                    checked={selectedBank === 'gtb'}
                    onChange={handleBankChange}
                    className="custom-radio"
                  />
                </div>
            </div>

            <div className="account-type-container">
                <div className="account-type" onClick={() => setSelectedBank('zenith')}>
                  <div className="account-type-text" style={{display:"flex", flexDirection:"row"}}>
                  <div>
                    <img src={process.env.PUBLIC_URL + "/images/ass-zenith.svg"} alt="logo"/>
                  </div>
                  <div>
                    <p className="type-of-account">Zenith Bank</p>
                    <p className="account-number">048••••917</p>
                  </div>
                  </div>
                  <input
                    type="radio"
                    name="options"
                    value="zenith"
                    checked={selectedBank === 'zenith'}
                    onChange={handleBankChange}
                    className="custom-radio"
                  />
                </div>
            </div>

            <div className="account-type-container">
                <div className="account-type" onClick={() => setSelectedBank('kuda')}>
                  <div className="account-type-text" style={{display:"flex", flexDirection:"row"}}>
                    <div>
                      <img src={process.env.PUBLIC_URL + "/images/ass-kuda.svg"} alt="logo"/>
                    </div>
                    <div>
                      <p className="type-of-account">Kuda Bank</p>
                      <p className="account-number">264••••714</p>
                    </div>
                  </div>
                  <input
                    type="radio"
                    name="options"
                    value="kuda"
                    checked={selectedBank === 'kuda'}
                    onChange={handleBankChange}
                    className="custom-radio"
                  />
                </div>
            </div>
            <button className="btn" style={{ background: "#2EBDB6" }} onClick={handleClick} disabled={isLoading}>
            {isLoading ? (
                  <BeatLoader
                    color="#FFF"
                    cssOverride={{}}
                  />              ) : (
                <>
                  Continue
                  <img src={process.env.PUBLIC_URL + "/images/arrow-forward.svg"} alt="arrow btn" style={{ display: "inline", marginLeft: "5px", width:"14px", height:"14px" }}/>
                </>
              )}
            </button>
            <button className="btn" style={{ background: "#FFF", color:"#161925", marginTop:"10px", border:"1px solid #EAECF0" }} onClick={handleConnect} disabled={isLoading}>
            {isLoading ? (
                  <BeatLoader
                    color="#FFF"
                    cssOverride={{}}
                  />              ) : (
                <>
                  Connect another account
                </>
              )}
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default DirectAssociatedAccounts;
