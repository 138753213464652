import React from 'react'
import styled from 'styled-components'


const ErrorPage = styled.div`
width:100%;
height:100vh;
background: #F6F7F9;
display:flex;
align-items: center;
justify-content:space-between;
flex-direction:column;
padding-bottom:6rem;
margin:0 auto;

`
const ErrorContent = styled.div`
border-radius: 10px;
background: #FFF;
width:100%;
max-width:380px;
display:flex;
align-items:center;y
justify-content:center;
text-align:center;
flex-direction:column;
padding-bottom:4rem;
margin-top:25vh;


.title{
    color: var(--gray-900, #101828);
    text-align: center;
    font-family: Circular Std;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.32px;
    margin-top:3rem;

}
.text{
    color: #fff;
    font-family: Circular Std;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 152.523%; /* 61.009px */
    background:#ED1C24;
    width:fit-content;
    padding:1rem;
    margin-top:3rem;
    
}`

function NotFound() {
  return (
    <ErrorPage >

        <ErrorContent>
                <h1 className='text' style={{fontSize:"18px"}}>404</h1>
                <h3 className='title'>We couldn't find this page</h3>
        </ErrorContent>
        <div className='securedBtn'>
            <img src='/images/lockUnlocked.svg' alt='lock' width="20" />
            <div className='secured_content'>
                <p className='secured_p'>Secured by  </p>
                <img src='/images/flickLogo.svg' alt='logo' width="40"/>
            </div>
        </div>
    </ErrorPage>
  )
}

export default NotFound
